<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="list">
						<div class="item" v-for="retention in retentions" @click="viewItem(retention)" v-bind:class="{active: retentionSelected.id == retention.id}">
							<div class="name">{{ retention.name }}</div>
							<div class="actions">
								<button @click.stop="editItem(retention)"><icon-edit /></button>
								<button v-if="retention.is_deletable" @click.stop="showModal('delete', {type: 'retention-nomenclature', from: 'nomenclature_retention', model: retention})"><icon-trash /></button>
							</div>
						</div>
						<div class="item-empty" v-if="!retentions.length">
							{{ $t('nomenclature.retention.no-result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon-header"><icon-retention /></div>
								<div class="name">{{ $t('nomenclature.retention.create') }}</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
                                        <div v-if="$v.retention.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.retention.name.$error}">
										<div class="icon-right" v-if="retention.name != ''" @click.stop="retention.name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="retention.name">
									</div>
								</div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.retention.is_taxable') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="retention.is_taxable = 0" v-bind:class="{active: retention.is_taxable === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="retention.is_taxable = 1" v-bind:class="{active: retention.is_taxable === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.retention.retain_from_CAS_CASS') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="retention.retain_from_CAS_CASS = 0" v-bind:class="{active: retention.retain_from_CAS_CASS === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="retention.retain_from_CAS_CASS = 1" v-bind:class="{active: retention.retain_from_CAS_CASS === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.calcul-type') }}*</label>
                                        <div v-if="$v.retention.selectedPercentage.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.retention.selectedPercentage.$error}">
                                        <div class="icon-left"><icon-calculator /></div>
                                        <div class="icon-right" v-if="retention.selectedPercentage != ''" @click.stop="retention.selectedPercentage = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="retention.selectedPercentage"
                                            v-bind:class="{populate: retention.selectedPercentage != ''}"
                                            class="select-tags-tbf"
                                            :options="percentageOptions"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            @input="retention.value = ''"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('salary.benefits.calcul-type-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div v-if="retention.selectedPercentage.id == 1" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.percentage') }}*</label>
                                        <div v-if="$v.retention.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.retention.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="text" class="input-text no-icon" :placeholder="$t('salary.benefits.percentage-ph')" v-model="retention.value">
                                    </div>
                                </div>
                                <div v-if="retention.selectedPercentage.id == 2" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.fix_sum') }}*</label>
                                        <div v-if="$v.retention.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.retention.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="number" class="input-text no-icon" :placeholder="$t('salary.benefits.fix_sum-ph')" v-model="retention.value">
                                    </div>
                                </div>

                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.retention.max_sum_retention') }}</label>
									</div>
									<div class="input-box bg-white">
										<div class="icon-right" v-if="retention.max_sum_retention != ''" @click.stop="retention.max_sum_retention = ''"><icon-close class="icon-clear" /></div>
										<input type="number" :placeholder="$t('nomenclature.retention.max_sum_retention-ph')" class="input-text no-icon" v-model="retention.max_sum_retention">
									</div>
								</div>
                                
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.retention.retention_base') }}</label>
                                    </div>
                                    <div class="input-box bg-white">
                                        <div class="icon-left"><icon-retention /></div>
                                        <div class="icon-right" v-if="retention.selectedBase != ''" @click.stop="retention.selectedBase = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="retention.selectedBase"
                                            v-bind:class="{populate: retention.selectedBase != ''}"
                                            class="select-tags-tbf"
                                            :options="optionsBases"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('nomenclature.retention.retention_base-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.add') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon-header"><icon-retention /></div>
								<div class="name">{{ retentionSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
                                        <div v-if="$v.retention.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box" v-bind:class="[{has_error: $v.retention.name.$error},{'bg-gray': !retentionSelected.is_deletable},{'bg-white': retentionSelected.is_deletable}]">
										<div class="icon-right" v-if="retention.name != '' && retentionSelected.is_deletable" @click.stop="retention.name = ''"><icon-close class="icon-clear" /></div>
										<input :disabled="!retentionSelected.is_deletable" type="text" :placeholder="$t('skills.name-ph')" class="input-text no-icon" v-model="retention.name">
									</div>
								</div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.retention.is_taxable') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="retention.is_taxable = 0" v-bind:class="{active: retention.is_taxable === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="retention.is_taxable = 1" v-bind:class="{active: retention.is_taxable === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.retention.retain_from_CAS_CASS') }}</label>
                                    </div>
                                    <div class="input-box bor-0">
                                        <div class="checkbox-list">
                                            <div class="checkbox-item m-4" @click="retention.retain_from_CAS_CASS = 0" v-bind:class="{active: retention.retain_from_CAS_CASS === 0}">
                                                <div class="name">{{ $t('create-user.no') }}</div>
                                            </div>
                                            <div class="checkbox-item m-4" @click="retention.retain_from_CAS_CASS = 1" v-bind:class="{active: retention.retain_from_CAS_CASS === 1}">
                                                <div class="name">{{ $t('create-user.yes') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.calcul-type') }}*</label>
                                        <div v-if="$v.retention.selectedPercentage.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.retention.selectedPercentage.$error}">
                                        <div class="icon-left"><icon-calculator /></div>
                                        <div class="icon-right" v-if="retention.selectedPercentage != ''" @click.stop="retention.selectedPercentage = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="retention.selectedPercentage"
                                            v-bind:class="{populate: retention.selectedPercentage != ''}"
                                            class="select-tags-tbf"
                                            :options="percentageOptions"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            @input="retention.value = ''"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('salary.benefits.calcul-type-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div v-if="retention.selectedPercentage.id == 1" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.percentage') }}*</label>
                                        <div v-if="$v.retention.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.retention.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="text" class="input-text no-icon" :placeholder="$t('salary.benefits.percentage-ph')" v-model="retention.value">
                                    </div>
                                </div>
                                <div v-if="retention.selectedPercentage.id == 2" class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('salary.benefits.fix_sum') }}*</label>
                                        <div v-if="$v.retention.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                                    </div>
                                    <div class="input-box bg-white" v-bind:class="{has_error: $v.retention.value.$error}">
                                        <div class="icon-left"></div>
                                        <input type="number" class="input-text no-icon" :placeholder="$t('salary.benefits.fix_sum-ph')" v-model="retention.value">
                                    </div>
                                </div>

                                <div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('nomenclature.retention.max_sum_retention') }}</label>
									</div>
									<div class="input-box bg-white">
										<div class="icon-right" v-if="retention.max_sum_retention != ''" @click.stop="retention.max_sum_retention = ''"><icon-close class="icon-clear" /></div>
										<input type="number" :placeholder="$t('nomenclature.retention.max_sum_retention-ph')" class="input-text no-icon" v-model="retention.max_sum_retention">
									</div>
								</div>
                                
                                <div class="input-group mb-15">
                                    <div class="label-header">
                                        <label class="label">{{ $t('nomenclature.retention.retention_base') }}</label>
                                    </div>
                                    <div class="input-box" v-bind:class="[{'bg-gray': !retentionSelected.is_deletable},{'bg-white': retentionSelected.is_deletable}]">
                                        <div class="icon-left"><icon-retention /></div>
                                        <div class="icon-right" v-if="retention.selectedBase != '' && retentionSelected.is_deletable" @click.stop="retention.selectedBase = ''"><icon-close class="icon-clear" /></div>
                                        <multiselect 
                                            v-model="retention.selectedBase"
                                            v-bind:class="{populate: retention.selectedBase != ''}"
                                            class="select-tags-tbf"
                                            :options="optionsBases"
                                            :allow-empty="true"
                                            :show-labels="false"
                                            track-by="id" 
                                            label="name"
                                            :disabled="!retentionSelected.is_deletable"
                                            >
                                            <template slot="placeholder" slot-scope="props">
                                                <span class="text">
                                                    {{ $t('nomenclature.retention.retention_base-ph') }}
                                                </span>
                                            </template>
                                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                                        </multiselect>
                                    </div>
                                </div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.update') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
								<div class="icon-header"><icon-retention /></div>
								<div class="name">{{ retentionSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editItem(retentionSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="data-item">
                                <div class="label">{{ $t('nomenclature.retention.is_taxable') }}</div>
								<div class="text">{{ retentionSelected.is_taxable ? $t('general.yes') : $t('general.no')}}</div>
                                <div class="label">{{ $t('nomenclature.retention.retain_from_CAS_CASS') }}</div>
								<div class="text">{{ retentionSelected.retain_from_CAS_CASS ? $t('general.yes') : $t('general.no')}}</div>
                                <div class="label">{{ $t('salary.benefits.calcul-type') }}</div>
								<div class="text">{{ retentionSelected.is_percentage ? $t('salary.benefits.percentage') : $t('salary.benefits.fix_sum')}} {{retentionSelected.value}}</div>
                                <div class="label" v-if="retentionSelected.max_sum_retention">{{ $t('nomenclature.retention.max_sum_retention') }}</div>
								<div class="text" v-if="retentionSelected.max_sum_retention">{{ retentionSelected.max_sum_retention}}</div>
                                <div class="label" v-if="retentionSelected.retention_base">{{ $t('nomenclature.retention.retention_base') }}</div>
								<div class="text">{{ retentionSelected.retention_base ? optionsBases.find(el => el.id == retentionSelected.retention_base.id).name : '' }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconRetention from '../../Icons/Retention'
    import IconCalculator from '../../Icons/Calculator'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconRetention,
            IconCalculator,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				retentions: [],
                optionsBases: [],
                percentageOptions: [{id: 1, name: this.$t('salary.benefits.percentage'), value: 1}, {id: 2, name: this.$t('salary.benefits.fix_sum'), value: 0}],
                retention: {
                    name: '',
                    is_taxable: 0,
                    retain_from_CAS_CASS: 0,
                    selectedPercentage: '',
                    value: '',
                    max_sum_retention: '',
                    selectedBase: ''

                },
				retentionSelected: '',
				error_message: '',
			}
		},
		async mounted(){
            await this.getRetentionBases()
			await this.getRetentions()

			setTimeout(() => {
				var title = this.$t('nomenclature-sidebar.deductions');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshRetentionsNomenclature', () => {
				this.viewCrud = 'create';
				this.getRetentions()
			})
		},
		validations: {
            retention:{
                name: { required },
                selectedPercentage: { required },
                value: { required },
            }
		},
		methods: {
            async getRetentionBases() {
                await axios.get(`/retention-bases`).then(({data}) => {
                   this.optionsBases = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
			async getRetentions(){
				await axios.get(this.$auth.user().instance.id + '/retentions')
				.then(({data}) => {
					this.retentions = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.retention.name.charAt(0).toUpperCase() + this.retention.name.slice(1),
                        is_taxable: this.retention.is_taxable,
                        retain_from_CAS_CASS: this.retention.retain_from_CAS_CASS,
                        is_percentage: this.retention.selectedPercentage ? this.retention.selectedPercentage.value : '',
                        value: this.retention.value,
                        max_sum_retention: this.retention.max_sum_retention,
                        retention_base_id: this.retention.selectedBase ? this.retention.selectedBase.id : '',
					}

					if(type == 'create'){
						this.createRetention(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateRetention(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createRetention(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/retentions/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getRetentions()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.retentionSelected = data.data;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editItem(retention) {
				this.scrollTop()
				this.viewCrud = 'edit'
				
				this.retentionSelected = retention
				this.retention.name = retention.name
                this.retention.is_taxable = retention.is_taxable
                this.retention.retain_from_CAS_CASS = retention.retain_from_CAS_CASS
                this.retention.selectedPercentage = this.percentageOptions.find(el => el.value == retention.is_percentage)
                this.retention.value = retention.value
                this.retention.max_sum_retention = retention.max_sum_retention
                this.retention.selectedBase =  retention.retention_base ? this.optionsBases.find(el => el.id == retention.retention_base.id) : ''
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			updateRetention(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/retentions/${this.retentionSelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getRetentions()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.retentionSelected = this.retentions.find(el => el.id == this.retentionSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.retentionSelected = ''
				}
				this.retention.name = ''
                this.retention.is_taxable = 0
                this.retention.retain_from_CAS_CASS = 0
                this.retention.selectedPercentage = ''
                this.retention.value = ''
                this.retention.max_sum_retention = ''
                this.retention.selectedBase = ''
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.retentionSelected = item
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>

<style lang="scss">
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>